import _defaults from "./defaults";
import _builder from "./builder";
import _parser from "./parser";
import _processors from "./processors";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.12.7
(function () {
  "use strict";

  var builder,
      defaults,
      parser,
      processors,
      extend = function (child, parent) {
    for (var key in parent) {
      if (hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      (this || _global).constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  },
      hasProp = {}.hasOwnProperty;

  defaults = _defaults;
  builder = _builder;
  parser = _parser;
  processors = _processors;
  exports.defaults = defaults.defaults;
  exports.processors = processors;

  exports.ValidationError = function (superClass) {
    extend(ValidationError, superClass);

    function ValidationError(message) {
      (this || _global).message = message;
    }

    return ValidationError;
  }(Error);

  exports.Builder = builder.Builder;
  exports.Parser = parser.Parser;
  exports.parseString = parser.parseString;
  exports.parseStringPromise = parser.parseStringPromise;
}).call(exports);
export default exports;
export const defaults = exports.defaults,
      processors = exports.processors,
      ValidationError = exports.ValidationError,
      Builder = exports.Builder,
      Parser = exports.Parser,
      parseString = exports.parseString,
      parseStringPromise = exports.parseStringPromise;