import _xmlbuilder from "xmlbuilder";
import _defaults from "./defaults";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.12.7
(function () {
  "use strict";

  var builder,
      defaults,
      escapeCDATA,
      requiresCDATA,
      wrapCDATA,
      hasProp = {}.hasOwnProperty;
  builder = _xmlbuilder;
  defaults = _defaults.defaults;

  requiresCDATA = function (entry) {
    return typeof entry === "string" && (entry.indexOf("&") >= 0 || entry.indexOf(">") >= 0 || entry.indexOf("<") >= 0);
  };

  wrapCDATA = function (entry) {
    return "<![CDATA[" + escapeCDATA(entry) + "]]>";
  };

  escapeCDATA = function (entry) {
    return entry.replace("]]>", "]]]]><![CDATA[>");
  };

  exports.Builder = function () {
    function Builder(opts) {
      var key, ref, value;
      (this || _global).options = {};
      ref = defaults["0.2"];

      for (key in ref) {
        if (!hasProp.call(ref, key)) continue;
        value = ref[key];
        (this || _global).options[key] = value;
      }

      for (key in opts) {
        if (!hasProp.call(opts, key)) continue;
        value = opts[key];
        (this || _global).options[key] = value;
      }
    }

    Builder.prototype.buildObject = function (rootObj) {
      var attrkey, charkey, render, rootElement, rootName;
      attrkey = (this || _global).options.attrkey;
      charkey = (this || _global).options.charkey;

      if (Object.keys(rootObj).length === 1 && (this || _global).options.rootName === defaults["0.2"].rootName) {
        rootName = Object.keys(rootObj)[0];
        rootObj = rootObj[rootName];
      } else {
        rootName = (this || _global).options.rootName;
      }

      render = function (_this) {
        return function (element, obj) {
          var attr, child, entry, index, key, value;

          if (typeof obj !== "object") {
            if (_this.options.cdata && requiresCDATA(obj)) {
              element.raw(wrapCDATA(obj));
            } else {
              element.txt(obj);
            }
          } else if (Array.isArray(obj)) {
            for (index in obj) {
              if (!hasProp.call(obj, index)) continue;
              child = obj[index];

              for (key in child) {
                entry = child[key];
                element = render(element.ele(key), entry).up();
              }
            }
          } else {
            for (key in obj) {
              if (!hasProp.call(obj, key)) continue;
              child = obj[key];

              if (key === attrkey) {
                if (typeof child === "object") {
                  for (attr in child) {
                    value = child[attr];
                    element = element.att(attr, value);
                  }
                }
              } else if (key === charkey) {
                if (_this.options.cdata && requiresCDATA(child)) {
                  element = element.raw(wrapCDATA(child));
                } else {
                  element = element.txt(child);
                }
              } else if (Array.isArray(child)) {
                for (index in child) {
                  if (!hasProp.call(child, index)) continue;
                  entry = child[index];

                  if (typeof entry === "string") {
                    if (_this.options.cdata && requiresCDATA(entry)) {
                      element = element.ele(key).raw(wrapCDATA(entry)).up();
                    } else {
                      element = element.ele(key, entry).up();
                    }
                  } else {
                    element = render(element.ele(key), entry).up();
                  }
                }
              } else if (typeof child === "object") {
                element = render(element.ele(key), child).up();
              } else {
                if (typeof child === "string" && _this.options.cdata && requiresCDATA(child)) {
                  element = element.ele(key).raw(wrapCDATA(child)).up();
                } else {
                  if (child == null) {
                    child = "";
                  }

                  element = element.ele(key, child.toString()).up();
                }
              }
            }
          }

          return element;
        };
      }(this || _global);

      rootElement = builder.create(rootName, (this || _global).options.xmldec, (this || _global).options.doctype, {
        headless: (this || _global).options.headless,
        allowSurrogateChars: (this || _global).options.allowSurrogateChars
      });
      return render(rootElement, rootObj).end((this || _global).options.renderOpts);
    };

    return Builder;
  }();
}).call(exports);
export default exports;